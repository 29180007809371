import { memo } from 'react';

import { LoadingWrapperStyled, LoaderStyled } from './styled/Loading';

const Loading = () => (
  <LoadingWrapperStyled data-testid="loading">
    <LoaderStyled />
  </LoadingWrapperStyled>
);

export default memo(Loading);
