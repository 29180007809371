import GuestHeader from '@oneflare/flarekit/lib/components/GuestHeader';
import {
  trackBusinessRegistrationInitiated,
  TrackBusinessRegistrationInitiatedOptions,
  trackLoginInitiated,
  TrackLoginInitiatedOptions
} from '@oneflare/web-analytics';
import { useRouter } from 'next/router';
import { memo, useMemo } from 'react';

import { OneflareAnalytics } from 'lib/analytics/oneflareAnalytics';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import SubscriptionSignUp from 'shared/components/_oneflare/SubscriptionSignUp';
import NotificationBanner from 'shared/components/NotificationBanner';
import { NotificationBannerMsgStyled } from 'shared/components/NotificationBanner/styled/NotificationBannerStyled';
import { NOTIFICATION_STATUS } from 'shared/components/NotificationBanner/utils';
import { useBreakpoint } from 'shared/utils/hooks';
import type { ICurrentUser } from 'types/oneflare.com.au/user';

import { HeaderKind } from '../../PageHeader';
import { PageHeaderStyled } from '../../styled/PageHeader';


export type PageHeaderOneflareProps = {
  currentUser: ICurrentUser;
  kind?: HeaderKind;
  categoryId: number | string;
  jobFormHost: string;
  hideGuestCta: boolean;
  initiatedAuthBeginPageName?: string;
  isOneflareClient: {
    openClientJobForm: () => Promise<void>;
  };
  zoneId?: number;
  mobileCta?: {
    link: string;
    label: string;
  };
};

const PageHeaderOneflare = ({
  currentUser,
  kind,
  hideGuestCta,
  isOneflareClient,
  zoneId,
  categoryId,
  jobFormHost,
  initiatedAuthBeginPageName,
  mobileCta
}: PageHeaderOneflareProps) => {
  const { ENVIRONMENT } = publicRuntimeConfig;
  const lg = useBreakpoint('lg');
  const router = useRouter();
  const asPath = router.asPath;
  const kindMemo = useMemo((): HeaderKind => {
    if (currentUser && currentUser.user && lg) {
      return HeaderKind.DEFAULT;
    }
    return kind;
  }, [currentUser, lg, kind]);

  const trackLoginInitiatedEvent = (event) => {
    const tracker = new OneflareAnalytics(
      currentUser?.user?.id ? String(currentUser.user.id) : undefined
    );
    trackLoginInitiated(tracker, 'client:PageHeaderStyled:onLoginClick', {
      initiatedPage: initiatedAuthBeginPageName
    } as TrackLoginInitiatedOptions);
    window.location.href = event.currentTarget.href;
  };

  const trackBusinessRegistrationInitiatedEvent = (event) => {
    const tracker = new OneflareAnalytics(
      currentUser?.user?.id ? String(currentUser.user.id) : undefined
    );
    trackBusinessRegistrationInitiated(
      tracker,
      'client:PageHeaderStyled:onBusinessRegisterClick',
      {
        initiatedPage: initiatedAuthBeginPageName,
        initiatedSection: 'Header',
        initiatedCtaText: 'Business Register',
        isLoggedIn: !!currentUser?.user?.id
      } as TrackBusinessRegistrationInitiatedOptions
    );
    if (event && event.currentTarget.tagName === 'A') {
      window.location.href = (event.currentTarget as HTMLAnchorElement).href;
    }
  };

  const { business, isBusiness, customerModeOverride } = currentUser?.user || {};

  const noSubOrCancelled = [undefined, 'cancelled']
    .includes(business?.subscription?.currentState);

  const creditsCta = isBusiness
    && !customerModeOverride
    && noSubOrCancelled
    && <SubscriptionSignUp />;

  return (
    <>
      {kindMemo === HeaderKind.OVERLAY || (currentUser && currentUser.user) ? (
        <>
          {currentUser?.notifications?.map((notification) => (
            <NotificationBanner
              status={NOTIFICATION_STATUS[notification.type.toUpperCase()]}
              key={notification.message}
              closable={notification.closable}
              action={notification.action}
            >
              <NotificationBannerMsgStyled
                dangerouslySetInnerHTML={{
                  __html: notification.message
                }}
              />
            </NotificationBanner>
          ))}
          <PageHeaderStyled
            {...currentUser}
            {...(currentUser?.user?.isBusiness && { mobileCta })}
            {...(currentUser?.trueUser && { trueUser: currentUser.trueUser.name })}
            creditsCta={creditsCta}
            currentPath={asPath}
            kind={kindMemo}
            withCobranding
            jobFormHost={jobFormHost}
            isOneFlareClient={isOneflareClient}
            currentEnv={ENVIRONMENT}
            onLoginClick={trackLoginInitiatedEvent}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            onBusinessRegisterClick={trackBusinessRegistrationInitiatedEvent}
          />
        </>
      ) : (
        <GuestHeader
          withCobranding
          categoryId={+categoryId}
          zoneId={zoneId}
          hideCta={hideGuestCta}
          onLoginClick={trackLoginInitiatedEvent}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onBusinessRegisterClick={trackBusinessRegistrationInitiatedEvent}
        />
      )}
    </>
  );
};

export default memo(PageHeaderOneflare);
