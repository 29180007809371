import { gql } from '@apollo/client';

export const PRODUCTS = gql`
{
  products {
    creditPlans {
      priceId
      name
      billingPeriod
      credits
      creditsFormatted
      price
      monthlyPriceFormatted
      totalPriceFormatted
      recommended
      estimatedLeadsLabel
      purchasable
      benefits {
        label
        included
      }
    }
  }
}
`;


type Benefit = {
  label: string;
  included: boolean;
};

export type CreditPlan = {
  priceId: string;
  name: string;
  price: number;
  billingPeriod: string;
  credits: number;
  creditsFormatted: string;
  monthlyPriceFormatted: string;
  totalPriceFormatted: string;
  estimatedLeadsLabel: string;
  purchasable?: boolean;
  recommended?: boolean;
  benefits: Benefit[];
};
