import type { ButtonProps } from '@oneflare/flarekit/lib/components/Button';
import Card from '@oneflare/flarekit/lib/components/Card';
import React, {
  useLayoutEffect,
  useCallback,
  useState,
  useMemo,
  useRef,
  KeyboardEvent,
  MouseEventHandler,
  ComponentProps,
  ComponentType
} from 'react';

import { CreditPlan } from 'queries/shared/products';

import { Container } from '../styled/PlanList';

import PlanInfo, { PlanInfoProps } from './PlanInfo';

const PlanCard = ({
  buttonProps: { ...button } = {},
  monthlyPriceFormatted,
  creditsFormatted,
  recommended,
  purchasable,
  selected,
  credits,
  onClick,
  ...props
}: Omit<PlanInfoProps, 'price' | 'credits'>
 & Parameters<typeof makeVariantProps>[0]
) => {
  const variantProps = useMemo(() => makeVariantProps({
    onClick: button.onClick,
    monthlyPriceFormatted,
    creditsFormatted,
    recommended,
    purchasable,
    selected,
    credits
  }), [
    button.onClick,
    monthlyPriceFormatted,
    creditsFormatted,
    recommended,
    purchasable,
    selected,
    credits
  ]);

  return (
    <Card
      style={selected ? null : { background: 'white' }}
      active={selected}
      onClick={onClick}
      onKeyDown={forwardToButton('Enter', ' ')}
    >
      <PlanInfo {...props} {...variantProps} />
    </Card>
  );
};

type CarouselProps<T> = ComponentProps<typeof Container> & {
  Item: ComponentType<T & {
    selected?: boolean
  }>
  selectable?(item: T): boolean
  defaultSelected?: number
  options: T[]
}

const Carousel = <T extends object>({
  defaultSelected,
  selectable,
  options,
  Item,
  ...props
}: CarouselProps<T>) => {
  const [ selectedIndex, setSelectedIndex ] = useState(defaultSelected);

  const select = useCallback((index: number) => {
    const item = options[index];
    if (item && (!selectable || selectable(item))) setSelectedIndex(index);
  }, [options, selectable]);

  const ref = useRef<HTMLDivElement>();
  const scrollIntoView = useCallback(() => {
    let { current } = ref;
    current = current?.children?.item?.(selectedIndex) as HTMLDivElement;
    if (!current) return;

    current.scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'center'
    });

    let tabbable = current.querySelector('[tabindex]') as HTMLElement;
    if (!tabbable || tabbable.tabIndex < 0) tabbable = current;
    tabbable.focus();
  }, [selectedIndex]);

  useLayoutEffect(scrollIntoView, [scrollIntoView]);

  const handleArrowKeys = useCallback((event: KeyboardEvent) => {
    const handler = {
      ArrowRight: () => select(selectedIndex + 1),
      ArrowLeft: () => select(selectedIndex - 1)
    }[event.key];

    handler?.();
  }, [select, selectedIndex]);

  return (
    <Container
      {...props}
      onKeyDown={handleArrowKeys}
      role="radiogroup"
      ref={ref}
    >
      {options.map((props, index) => {
        const selected = index === selectedIndex;

        return (
          <div
            key={index}
            role="radio"
            aria-checked={selected}
            onClick={() => select(index)}
          >
            <Item {...props} selected={selected} />
          </div>
        );
      })}
    </Container>
  );
};

type PlanListProps = {
  onContinue?(_: CreditPlan): void
  options: Array<CreditPlan>
}

const PlanList = ({ options, onContinue }: PlanListProps) => {
  const initialSelection = useMemo(() => {
    const index = options.findIndex(it => it.recommended);
    return index == -1 ? 1 : index;
  }, [options]);

  return (
    <Carousel
      defaultSelected={initialSelection}
      selectable={plan => plan.credits > 0}
      Item={({ selected, ...plan }) =>
        <PlanCard
          {...plan}
          selected={selected}
          buttonProps={{ onClick: () => onContinue?.(plan) }}
        />
      }
      options={options}
    />
  );
};

const makeVariantProps = ({
  monthlyPriceFormatted,
  creditsFormatted,
  recommended,
  purchasable,
  selected,
  credits,
  onClick
}: {
  onClick?: MouseEventHandler
  monthlyPriceFormatted: string
  creditsFormatted: string
  purchasable?: boolean
  recommended?: boolean
  selected?: boolean
  credits: number
}) => {
  const buttonProps: ButtonProps = {
    kind : selected ? 'primary-sm' : 'default-sm',
    label: 'Continue',
    onClick
  };

  const payg = !credits;
  const professional = !purchasable && !payg;

  if (payg) {
    buttonProps.label = 'Current Plan';
    buttonProps.disabled = true;
  } else if (!selected) buttonProps['aria-disabled'] = true;

  if (professional) buttonProps.label = 'Let\'s Talk';

  return {
    billingPeriod: professional ? '' : 'month',
    tags: recommended ? ['Recommended'] : [],
    price: monthlyPriceFormatted,
    credits: creditsFormatted,
    buttonProps
  };
};

// keys to trigger the first button inside
const forwardToButton = (...keys: string[]) => (
  event: KeyboardEvent<HTMLElement>
) => {
  const button = event.currentTarget.querySelector('button');

  if (button && keys.includes(event.key)) {
    event.preventDefault();
    button.click();
  }
};

export default PlanList;
