import AutoComplete from '@oneflare/flarekit/lib/components/AutoComplete';
import Button from '@oneflare/flarekit/lib/components/Button';
import Cobranding from '@oneflare/flarekit/lib/components/logos/Cobranding';
import WeddingLogo from '@oneflare/flarekit/lib/components/logos/Wedding';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const HeaderStickyStyled = styled.div<{
  $showStickyHeader: boolean;
}>`
  background-color: ${({ theme }) => theme.color.white};
  border-bottom: 1px solid ${({ theme: { color } }) => color.cloud};
  padding: 0;
  position: fixed;
  top: ${({ $showStickyHeader }) => ($showStickyHeader ? 0 : '-73px')};
  transition: top 0.3s ease-in;
  width: 100%;
  z-index: 500;
`;

export const ContainerStyled = styled.section`
  padding: 0 20px;
  max-width: 1140px;
  margin: 0 auto;
  box-sizing: content-box;
  display: flex;
  height: 72px;
  justify-content: space-between;

  ${media.lgUp`
    max-width: 100%;
    padding: 0 40px;
  `}
`;

export const CobrandingStyled = styled(Cobranding).attrs(({ theme }) => ({
  fill: theme.color.gunMetal
}))`
  display: block;
  line-height: 0;
  width: 117px;

  ${media.lgUp`
    line-height: 1.5;
    width: 150px;
  `}
`;

export const WeddingLogoStyled = styled(WeddingLogo).attrs(({ theme }) => ({
  fill: theme.color.gunMetal
}))`
  display: block;
  width: 150px;
  line-height: 0;

  ${media.mdUp`
    width: 200px;
  `}
`;

export const LogoWrapperStyled = styled.div`
  align-items: center;
  display: flex;
  padding: 0;
`;

export const QuoteButtonStyled = styled(Button)`
  display: block;
  margin: 15px 0 16px;
  ${media.mdUp`
    display: none;
  `}
`;

export const SearchStyled = styled.div`
  display: none;
  ${media.mdUp`
    align-items: center;
    display: flex;
    justify-content: flex-end;
  `}
`;

export const AutoCompleteStyled = styled(AutoComplete)`
  display: flex;

  .AutoComplete__input {
    border-radius: 0;
    font-size: 14px !important;
    padding: 9px 10px;
    width: 160px;

    &:focus {
      box-shadow: none;
    }

    &::selection {
      background: ${({ theme }) => theme.color.success};
      color: ${({ theme }) => theme.color.white} !important;
    }
  }

  .Label {
    align-items: center;
    color: ${({ theme }) => theme.color.gunMetal};
    display: flex;
    font-family: ${({ theme }) => theme.font.primaryRegular};
    font-size: 14px;
    font-weight: 600;
    height: 39px;
    justify-content: flex-end;
    line-height: 24px;
    margin: 0;
    padding: 0 16px;
    text-align: right;
  }

  .AutoComplete__input-wrapper {
    position: relative;
  }

  ul {
    text-align: left;
  }
`;

export const SearchButtonStyled = styled(Button)`
  ${media.mdUp`
    margin-left: 16px;
  `}
`;

export const ButtonStyled = styled(Button)`
  font-size: 14px;
  align-self: center;

  ${media.mdUp`
    font-size: 16px;
  `}
`;

export const CTAStyled = styled.div`
  align-items: center;
  display: flex;
`;
