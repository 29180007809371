import { styled } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 217px;
  min-height: 486px;
`;

export const Title = styled.h3`
  margin: 0;
  font-size: 18px;
  font-weight: 700;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-transform: capitalize;

  > :first-child {
    margin-right: auto;
  }
`;

export const Credits = styled.span`
  color: ${({ theme }) => theme.color.primary};
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  margin-top: ${({ theme }) => theme.spacing.md};
`;

export const Price = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.md};
  font-size: 32px;
  font-weight: 600;

  > span:last-child {
    margin-left: ${({ theme }) => theme.spacing.xs};
    font-size: 16px;
    font-weight: normal;
  }
`;

export const Features = styled.ul`
  list-style: none;
  padding: 0;
  margin: ${({ theme }) => theme.spacing.lg} 0 0;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &:empty { display: none }

  ${({ theme: { color, spacing } }) => `
    border-top: 1px solid ${color.aluminium};
    padding-top: ${spacing.lg};
  `}
`;

export const Feature = styled.li.attrs<{ $disabled?: boolean }>(
  ({ $disabled }) => ({
    role: 'checkbox',
    'aria-readonly': true,
    'aria-checked': !$disabled
  })
)(({ $disabled, theme: { color } }) => `
  display: flex;
  align-items: start;
  font-size: 13px;
  font-weight: 400;
  line-height: 19px;
  color: ${color.gunMetal};

  ${$disabled && `
    color: ${color.aluminium};

    &:before {
      font-size: 1.5em;
      font-weight: 100;
      content: '×';
      color: ${color.danger};
    }
  `}

  &:before,
  >:first-child { margin-right: .5em; }
`);

export const Description = styled.span(({ theme: { spacing, color } }) => `
  padding-top: ${spacing.md};
  color: ${color.silver};
`);
