import { useMutation } from '@apollo/client';
import { AlertContainer, Modal } from '@oneflare/flarekit/lib/components';
import { useEffect, useState } from 'react';

import { globalAlertContainer } from 'lib/alerts/globalAlertContainer';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { getSiteUrl } from 'lib/utils/Environment';
import { CREATE_CHECKOUT_SESSION } from 'mutations/shared/createCheckoutSession';
import { CreditPlan } from 'queries/shared/products';


import {
  ButtonStyled,
  TitleStyled,
  ParagraphStyled,
  SignUpContainerStyled,
  BillingOptionsStyled,
  BillingSummaryStyled,
  SummaryItemStyled,
  SummaryTitleStyled,
  SummaryTotalStyled,
  TermAndConditionStyled,
  LinkStyled
} from '../styled/SignUpModal';

import BillingCycles from './BillingCycles';

type SignUpModalProps = {
  billingOptions: CreditPlan[]
  onClose?(): void
  show: boolean
}
export default function SignUpModal({
  billingOptions,
  onClose,
  show
}: SignUpModalProps) {
  const [createCheckoutSession, { loading }] = useMutation(CREATE_CHECKOUT_SESSION, {
    onCompleted: ({ createStripeCheckoutSession: { url } }) => {
      window.location.href = url;
    },
    onError: (error) => {
      globalAlertContainer.current?.error({ message: error.message });
      DataDogRumAgent.addRumError(error, 'Oneflare | Subscription Signup | CREATE_CHECKOUT_SESSION mutation');
    }
  });

  const [selectedPlan, setSelectedPlan] = useState(billingOptions[0]);

  useEffect(
    () => setSelectedPlan(billingOptions[0]),
    [billingOptions]
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    createCheckoutSession({
      variables: {
        creditPlanPriceId: selectedPlan.priceId
      }
    });
  };

  return (
    <Modal
      isCloseable
      disableBodyPadding
      style={{ width: '100%', maxWidth: '880px', display: 'flex', borderRadius: '16px' }}
      onClose={onClose}
      show={show}
    >
      <AlertContainer ref={globalAlertContainer} />
      <form onSubmit={handleSubmit}>
        <SignUpContainerStyled>
          <BillingOptionsStyled>
            <TitleStyled>Almost done!</TitleStyled>
            <ParagraphStyled>Confirm your billing cycle</ParagraphStyled>
            <BillingCycles
              billingCycleOptions={billingOptions}
              setSelectedPlan={setSelectedPlan}
              selectedPlan={selectedPlan}
            />
          </BillingOptionsStyled>
          <BillingSummaryStyled>
            <SummaryTitleStyled>Your order summary</SummaryTitleStyled>
            <SummaryItemStyled>
              <b>{selectedPlan?.name} Plan</b>
              <b>{selectedPlan?.totalPriceFormatted}</b>
            </SummaryItemStyled>
            <SummaryItemStyled>
              <span>Billed {selectedPlan?.billingPeriod}</span>
            </SummaryItemStyled>
            <SummaryItemStyled>
              <span>{selectedPlan?.creditsFormatted}</span>
            </SummaryItemStyled>
            <SummaryTotalStyled>
              <span>Total due today</span>
              <span>AUD {selectedPlan?.totalPriceFormatted}</span>
            </SummaryTotalStyled>
            <ButtonStyled
              type="submit"
              label="Continue to checkout"
              kind="primary"
              isLoading={loading}
              onClick={handleSubmit}
            />
            <TermAndConditionStyled>
              By clicking ‘Continue to checkout’, you agree to Oneflare’s{' '}
              <LinkStyled
                href={`${getSiteUrl()}/terms`}
                target="_blank"
              >
                Terms & Conditions
              </LinkStyled>{' '}
              and{' '}
              <LinkStyled
                href={`${getSiteUrl()}/privacy`}
                target="_blank"
              >
                Privacy Policy
              </LinkStyled>
              .
            </TermAndConditionStyled>
          </BillingSummaryStyled>
        </SignUpContainerStyled>
      </form>
    </Modal>
  );
}
