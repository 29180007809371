import Anchor from '@oneflare/flarekit/lib/components/Anchor';
import { capitalize } from 'lodash';
import { Fragment } from 'react';

import { CreditPlan } from 'queries/shared/products';

import {
  PricingOptionStyled,
  PricingDetailsStyled,
  PriceStyled,
  CreditStyled,
  CardStyled,
  CardNoteStyled,
  RadioStyled
} from '../styled/BillingCycles';

const billingPeriodLabel = {
  'monthly': 'month',
  'quarterly': '3 months',
  'half-yearly': '6 months',
  'yearly': 'year'
};

type BillingCyclesProps = {
  billingCycleOptions: CreditPlan[]
  selectedPlan?: CreditPlan
  setSelectedPlan: (plan: CreditPlan) => void
}

export default function BillingCycles({
  billingCycleOptions,
  selectedPlan,
  setSelectedPlan
}: BillingCyclesProps) {
  const yearlyOption = billingCycleOptions.find((option) => option.billingPeriod === 'yearly');

  return billingCycleOptions.map((option) => {
    const isActive = selectedPlan?.priceId === option.priceId;

    return (
      <Fragment key={option.priceId}>
        <CardStyled
          active={isActive} onClick={() => setSelectedPlan(option)}
          $expanded={selectedPlan?.billingPeriod == option.billingPeriod}
        >
          <PricingOptionStyled>
            <RadioStyled
              id={option.priceId}
              label={capitalize(option.billingPeriod)}
              checked={isActive}
              name="creditPlanPriceId"
              value={option.priceId}
              onChange={() => setSelectedPlan(option)}
            />
            <PricingDetailsStyled>
              <PriceStyled><b>{option.monthlyPriceFormatted}</b>/mo</PriceStyled>
              <CreditStyled>Receive <b>{option.creditsFormatted}</b> every {billingPeriodLabel[option.billingPeriod] || option.billingPeriod}</CreditStyled>
            </PricingDetailsStyled>
          </PricingOptionStyled>
        </CardStyled>
        {option.billingPeriod == 'monthly' && (
          <CardNoteStyled
            border={false}
            size="small"
            disabled
          >
            <p>
              <b>Note:</b> Unused credits do not roll over. Choose a <Anchor onClick={() => setSelectedPlan(yearlyOption)}>yearly</Anchor> subscription and get 12x more time to spend your credits
            </p>
          </CardNoteStyled>
        )}
      </Fragment>
    );
  });
}