import { styled } from 'styled-components';

export const Container = styled.div(({ theme: { spacing } }) => `
  display: flex;
  gap: ${spacing.md};
  padding: ${spacing.md} 0;
  scroll-padding: ${spacing.md};
  scroll-snap-type: x mandatory;
  overflow-x: auto;
  max-width: 100%;
  user-select: none;
  scrollbar-width: none;

  ::-webkit-scrollbar { display: none }

  > [tabindex] {
    scroll-snap-align: center;
  }

  & [title]:focus { outline: none }
`);
