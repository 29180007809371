import Button, { ButtonProps } from '@oneflare/flarekit/lib/components/Button';
import Tick from '@oneflare/flarekit/lib/components/icons/Tick';
import Tag from '@oneflare/flarekit/lib/components/Tag';
import React from 'react';

import { useThemeContext } from 'shared/components/HighOrderComponent/withThemeProvider/withThemeProvider';

import {
  Credits,
  Features,
  Feature,
  Container,
  Description,
  Price,
  Title
} from '../styled/PlanInfo';

export type PlanInfoProps = {
  name: string;
  credits: string;
  price: string
  estimatedLeadsLabel?: string;
  billingPeriod?: string;
  buttonProps?: ButtonProps
  tags?: React.ReactNode[]
  benefits?: Array<{ label: string, included: boolean }>;
};

const PlanInfo = ({
  buttonProps: { ...button },
  credits,
  price,
  billingPeriod,
  estimatedLeadsLabel,
  name,
  benefits = [],
  tags = []
}: PlanInfoProps) => {
  const { color } = useThemeContext();

  return (
    <Container tabIndex={0} title={name}>
      <Title>
        <span>{name}</span>
        {tags.map((tag, index) =>
          <Tag
            key={index}
            border={false}
            kind="warning"
            size="medium"
          >{tag}</Tag>
        )}
      </Title>

      <Credits>{credits}</Credits>
      <Price>
        {price}
        {billingPeriod && <span>/{billingPeriod}</span>}
      </Price>

      {button.label && <Button title={estimatedLeadsLabel} {...button} />}

      {estimatedLeadsLabel && <Description>{estimatedLeadsLabel}</Description>}

      <Features>
        {benefits.map(({ label, included }, index) =>
          <Feature key={index} $disabled={!included}>
            {included && (
              <Tick
                tabIndex={-1} // had 0 as default
                fill={color.success}
                width="1em"
              />
            )}
            {label}
          </Feature>
        )}
      </Features>
    </Container>
  );
};

export default PlanInfo;
