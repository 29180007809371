import { useQuery } from '@apollo/client';
import { H3 } from '@oneflare/flarekit/lib/components';
import Accordion from '@oneflare/flarekit/lib/components/Accordion';
import Button from '@oneflare/flarekit/lib/components/Button';
import React, { useEffect, useState } from 'react';

import featureFlag from 'lib/utils/featureFlag';
import { PRODUCTS } from 'queries/shared/products';
import Loading from 'shared/components/Loading';
import { FEATURE_FLAGS, CONTACT_NUMBER } from 'shared/utils/constants';

import ContactModal from './components/ContactModal';
import PlanSelect from './components/PlanSelect';
import { DialogStyled, HeaderContentContainer, LogoStyled, ContactUsText, ContactUsAnchor, Faqs } from './styled/SignUpDialog';

export default function SubscriptionSignUp () {
  const [stripeEnabled, setStripeEnabled] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    const checkSubscriptionFlag = async () =>
      setStripeEnabled(await featureFlag.isEnabled(FEATURE_FLAGS.stripeSubscription));

    checkSubscriptionFlag();
  }, []);

  if (!stripeEnabled) return null;

  return <>
    <Button
      onClick={() => setShowDialog(true)}
      kind="outline-dark-sm"
      label="Upgrade"
    />
    <SignUpDialog
      onClose={() => setShowDialog(false)}
      show={showDialog}
    />
  </>;
}

const SignUpDialog = ({ show, onClose }: {
  onClose(): void
  show: boolean
}) => {
  const { data }= useQuery(PRODUCTS);
  const { creditPlans } = data?.products || {};
  const [ contactModalOn, setContactModal ] = useState(false);

  return (
    <DialogStyled
      show={show}
      onClose={onClose}
      isCloseable
      headerContent={
        <HeaderContentContainer>
          <ContactUsText>
            Need help? <ContactUsAnchor onClick={setContactModal}>Speak to our team</ContactUsAnchor>
          </ContactUsText>
          <LogoStyled width="32px" kind="logo" />
        </HeaderContentContainer>
      }
    >
      {creditPlans ? <>
        <ContactModal
          onClose={() => setContactModal(false)}
          show={contactModalOn}
        />
        <PlanSelect
          showContactModal={() => setContactModal(true)}
          creditPlans={creditPlans}
        />
        <Faqs>
          <H3>Frequently asked questions</H3>
          <Accordion items={FAQS} />
        </Faqs>
      </> : <Loading />}
    </DialogStyled>
  );
};

const FAQS = Object
  .entries({
    'Do credits roll over?': <ul>
      <li> Credits do not roll over, instead your credit balance resets at the start of each billing cycle.</li>
      <li> Longer billing periods (quarterly, half-yearly, yearly) give you more time to use your credits.</li>
    </ul>,
    'How much does a lead cost?': <ul>
      <li> Leads are dynamically priced based on the location, demand and type of job.</li>
      <li> You'll always see the exact credit cost before purchasing any lead.</li>
      <li> Speak with us on {CONTACT_NUMBER} to explore the cost of leads in your area.</li>
    </ul>,
    'What if run out of credits?': <ul>
      <li> Good news, you can always add more credits when you are on a Oneflare membership!</li>
      <li> Just go to the "Add credits" tab in the app to purchase more or upgrade your plan if needed.</li>
    </ul>,
    'Am I locked into a contract?': <ul>
      <li> No, you're not locked into a contract as our plans are designed to be flexible and suit your business
      needs.</li>
      <li> You can cancel your membership anytime with 15 days notice.</li>
    </ul>,
    'What support does Oneflare offer?': <ul>
      <li> As part of every plan, we offer full end-to-end support from the moment you start your journey with Oneflare through a dedicated Account Manager who will be assigned to your account.</li>
      <li> Speak with us on {CONTACT_NUMBER} if you would like to learn more.</li>
    </ul>
  })
  .map(([label, content]) => ({ label, content }));
