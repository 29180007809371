import Cobranding from '@oneflare/flarekit/lib/components/logos/Cobranding';
import isEmpty from 'lodash/isEmpty';
import { useRouter } from 'next/router';
import { memo, useMemo, useContext, forwardRef } from 'react';

import { AnalyticsJobFormCtaText, AnalyticsJobFormSection, AnalyticsPage } from 'lib/analytics/constants';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { publicRuntimeConfig } from 'lib/utils/Environment';
import { useCurrentUser } from 'shared/utils/getCurrentUser';
import { indefiniteArticleCheck } from 'shared/utils/helpers';
import { useBreakpoint } from 'shared/utils/hooks';
import type { CategoryModel } from 'types/shared';

import HeaderSticky from '../HeaderSticky/HeaderSticky';

import { CTA_TYPES } from './Assets';
import OpenInAppStickyHeader from './components/OpenInAppStickyHeader';
import { HeaderVariants } from './HeaderVariants';
import { HeaderLoading } from './styled/PageHeader';

export enum HeaderKind {
  DEFAULT = 'default',
  OVERLAY = 'overlay',
  BORDERLESS = 'bordless',
  OVERLAY_DARK = 'overlayDark',
  OVERLAY_LIGHT = 'overlayLight'
}

export interface IPageHeader {
  category?: Pick<CategoryModel, 'slug' | 'singular' | 'plural' | 'id' | 'noun' | 'verb'>;
  categoryId?: number | string;
  className?: string;
  directLeadsNumber?: string;
  ctaKind?: (typeof CTA_TYPES)[keyof typeof CTA_TYPES];
  directLeadsTracking?: () => void;
  fetchPolicy?: string;
  handleCallClick?: () => void;
  hideGuestCta?: boolean;
  hideStickyHeader?: boolean;
  autoCompleteTitle?: string;
  isBot?: boolean;
  job_form_question?: string;
  kind?: HeaderKind;
  loading?: boolean;
  mobileCta?: {
    link: string;
    label: string;
  };
  openInAppStickyHeader?: boolean;
  postcode?: string;
  zoneId?: number;
  jobFormInitiatedPageName?: AnalyticsPage;
  initiatedAuthBeginPageName?: string;
}

const PageHeader = forwardRef<HTMLDivElement, IPageHeader>(({
  className = '',
  category,
  categoryId,
  job_form_question = '',
  ctaKind = CTA_TYPES.POST_JOB,
  directLeadsNumber,
  zoneId,
  hideStickyHeader = false,
  postcode = '',
  kind = HeaderKind.DEFAULT,
  isBot = false,
  openInAppStickyHeader = false,
  mobileCta,
  hideGuestCta = false,
  initiatedAuthBeginPageName,
  jobFormInitiatedPageName
}, ref) => {
  const isMobile = !useBreakpoint('lg');
  const { controller } = useContext(JobFormControllerContext);
  const { DOMAIN, JOB_FORM_URL } = publicRuntimeConfig;
  const isOneflare = DOMAIN === 'oneflare';
  const router = useRouter();
  const query = router?.query;
  const queryJobFormHost = query?.jobFormHost as string;
  const jobFormHost = queryJobFormHost || JOB_FORM_URL;

  const { loading: currentUserLoading, currentUser } = useCurrentUser({ fetchPolicy: 'cache-first' });

  const noBusinessUser = !currentUser?.user?.isBusiness; 
  
  const showOpenInAppStickyHeader = openInAppStickyHeader && noBusinessUser && isMobile;
  const showStickyWebHeader = !hideStickyHeader && noBusinessUser && !showOpenInAppStickyHeader;

  const autoCompleteTitle = useMemo(() => {
    if (ctaKind === CTA_TYPES.POST_JOB_AUTOCOMPLETE && !isEmpty(category)) {
      return `Where do you need ${String(indefiniteArticleCheck(category?.noun)).toLowerCase()}?`;
    }
    return job_form_question;
  }, [ctaKind, category, job_form_question]);

  const augmentedUser = useMemo(() => {
    if (currentUser?.user) {
      // TODO: This should occur in current-user context
      DataDogRumAgent.setRumUser(currentUser.user);
      
      return {
        ...currentUser,
        user: {
          ...currentUser.user,
          isAdmin: currentUser.user.roles?.includes('super_admin'),
          avatarUrl: currentUser.user.avatar?.url
        }
      };
    }
  }, [currentUser]);

  if (isOneflare && isEmpty(currentUser?.user) && currentUserLoading) {
    return (
      <HeaderLoading $isOverlay={kind === HeaderKind.OVERLAY}>
        <Cobranding />
      </HeaderLoading>
    );
  }

  return (
    <section className={className} ref={ref}>
      <HeaderVariants
        DOMAIN={DOMAIN}
        category={category}
        categoryId={categoryId}
        currentUser={augmentedUser}
        hideGuestCta={hideGuestCta}
        jobFormHost={jobFormHost}
        initiatedAuthBeginPageName={initiatedAuthBeginPageName}
        isOneflareClient={{
          openClientJobForm: async () => {
            await controller.open({
              page: jobFormInitiatedPageName,
              section: AnalyticsJobFormSection.NavigationBar,
              ctaText: AnalyticsJobFormCtaText.PostAJob
            });
          }
        }}
        kind={kind}
        mobileCta={mobileCta}
        zoneId={zoneId}
      />
      {showOpenInAppStickyHeader && <OpenInAppStickyHeader />}
      {showStickyWebHeader && (
        <HeaderSticky
          directLeadsNumber={directLeadsNumber}
          ctaKind={ctaKind}
          category={category}
          postcode={postcode}
          useGetFreeQuoteText={!isBot}
          autocompleteTitle={autoCompleteTitle}
          jobFormInitiatedPageName={jobFormInitiatedPageName}
        />
      )}
    </section>
  );
});

PageHeader.displayName = 'PageHeader';

export default memo(PageHeader);
