import { gql } from '@apollo/client';

export const GET_FEATURE_FLAGS = gql`
  query GetFeatureFlags($names: [String!]!) {
    appConfiguration {
      featureFlags(names: $names) {
        name
        enabled
      }
    }
  }
`;
