import Dropdown from '@oneflare/flarekit/lib/components/Dropdown';
import H2 from '@oneflare/flarekit/lib/components/H2';
import capitalize from 'lodash/capitalize';
import React, { useMemo, useState } from 'react';

import { CreditPlan } from 'queries/shared/products';

import { Container } from '../styled/PlanSelect';

import PlanList from './PlanList';
import SignUpModal from './SignUpModal';

enum BillingPeriod {
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  HALF_YEARLY = 'half-yearly',
  YEARLY = 'yearly'
}

const BILLING_OPTIONS = Object
  .values(BillingPeriod)
  .map(value => ({ value, label: capitalize(value) }));


type PlanSelectProps = {
  defaultBilling?: BillingPeriod
  creditPlans: CreditPlan[]
  showContactModal(): void
}

const PlanSelect = ({
  showContactModal,
  defaultBilling = BillingPeriod.MONTHLY,
  creditPlans
}: PlanSelectProps) => {
  const [billingPeriod, setBilling] = useState(defaultBilling as string);
  const [planName, setPlanName] = useState<string>();
  const [showSignUpModal, setShowSignUpModal] = useState(false);

  const filteredByBillingPeriod = useMemo(
    () => creditPlans.filter(it => it.billingPeriod == billingPeriod),
    [creditPlans, billingPeriod]
  );

  const filteredByPlanName = useMemo(
    () => creditPlans.filter(it => it.name === planName),
    [creditPlans, planName]
  );

  const onSelectPlan = (plan: CreditPlan) => {
    setPlanName(plan.name);

    if (plan.purchasable) setShowSignUpModal(true);
    else showContactModal();
  };

  return (
    <Container>
      <H2>Choose the plan that's right for you</H2>
      <Dropdown
        id="PlanSelect__dropdown"
        rounded
        contentOffset={4}
        variant="primary"
        options={BILLING_OPTIONS}
        handleChange={({ value }) => setBilling(value)}
        value={billingPeriod}
      />
      <PlanList
        options={filteredByBillingPeriod}
        onContinue={onSelectPlan}
      />
      <SignUpModal
        billingOptions={filteredByPlanName}
        onClose={() => setShowSignUpModal(false)}
        show={showSignUpModal}
      />
    </Container>
  );
};

export default PlanSelect;
