import { Anchor, Paragraph } from '@oneflare/flarekit/lib/components';
import Dialog from '@oneflare/flarekit/lib/components/Dialog/Dialog';
import OneflareLogo from '@oneflare/flarekit/lib/components/logos/2018/Oneflare';
import { styled } from 'styled-components';

export const DialogStyled = styled(Dialog)(({ theme: { color, spacing } }) => `
  &__overlay {
    display: flex;
    flex-direction: column;

    > :first-child {
      margin-bottom: calc(${spacing.xxxxxl} - 396px);
      background: ${color.primaryLighter};
      height: 396px;
    }

    > :last-child {
      max-width: 100vw;
      padding: 0 0 ${spacing.xxxxxl};
      margin: 0;
    }
  }
`);

export const HeaderContentContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoStyled = styled(OneflareLogo)`
  margin: 0 auto;
`;

export const ContactUsText = styled(Paragraph)`
  color: ${({ theme }) => theme.color.primary};
  position: absolute;
`;

export const ContactUsAnchor = styled(Anchor)`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
`;

export const Faqs = styled.div(({ theme: { spacing, breakpoint } }) => `
  flex-direction: column;
  display: flex;
  max-width: ${breakpoint.md.min};
  margin: ${spacing.xxxxxl} auto;

  > h3 {
    font-size: 22px;
    font-weight: 600;
    margin: ${spacing.lg} auto;
  }
`);
