import Card from '@oneflare/flarekit/lib/components/Card';
import Radio from '@oneflare/flarekit/lib/components/Radio';
import { styled } from 'styled-components';

export const CreditStyled = styled.div`
  color: ${({ theme }) => theme.color.gunMetal};
  font-size: 14px;
  font-weight: 400;
`;

export const PriceStyled = styled.div`
  color: ${({ theme }) => theme.color.charcoal};
  margin-bottom: ${({ theme }) => theme.spacing.xs};
`;

export const PricingDetailsStyled = styled.div`
  text-align: right;
`;

export const PricingOptionStyled = styled.div`
  align-items: center;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.md};
  transition: all 0.2s ease;
`;

export const RadioStyled = styled(Radio)`
  margin-right: ${({ theme }) => theme.spacing.sm};

  .Radio__label__text {
    white-space: nowrap;
    font-weight: 700;
    color: ${({ theme }) => theme.color.charcoal};
  }
`;

export const CardStyled = styled(Card)<{ $expanded: boolean }>(
  ({ theme: { spacing }, $expanded }) => {
    const gap = spacing.md;
    // at collapse, note's margin = 1/2 the gap, and its height = 0
    // makes it appear like any normal gap
    const noteMargin = $expanded ? gap : `calc(${gap} / 2)`;
    const transition = '.3s ease';

    return `
      width: 100%;
      padding: 0;
      border-width: 1px;
      margin-top: ${gap};

      & + ${CardNoteStyled} {
        margin: ${noteMargin} ${spacing.xs};
        max-height: ${$expanded ? '10em' : 0};
        opacity: ${$expanded ? 1 : 0};
        transition:
          margin ${transition},
          max-height ${transition},
          opacity ${transition};
      }

      ${CardNoteStyled} + & {
        // overide the gap if there is a note
        margin-top: 0;
      }
    `;
  }
);

export const CardNoteStyled = styled(Card)(
  ({ theme: { color, spacing } }) => `
    display: block;
    background: ${color.tin};
    font-size: 13px;
    line-height: 19px;
    padding: 0 ${spacing.md};
    overflow: hidden;

    a {
      color: ${color.link};
      cursor: pointer;
      text-decoration: none;

      &:hover {
        color: ${color.linkHover};
      }
    }
  `
);
