import { useLazyQuery } from '@apollo/client';
import { Tracker } from '@oneflare/flarekit/lib/libs';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useContext, useState, useEffect, useCallback, useMemo } from 'react';

import {
  AnalyticsJobFormCtaText,
  AnalyticsJobFormSection
} from 'lib/analytics/constants';
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { JobFormControllerContext } from 'lib/oneflare-job-form/utils/context';
import { getDomain, getSiteUrl } from 'lib/utils/Environment';
import { AUTOCOMPLETE_LOCATION } from 'queries/shared/autocompleteLocation';
import { CTA_TYPES } from 'shared/components/PageHeader/Assets';
import { CategoryObjectType } from 'shared/types';
import { extractPostcode } from 'shared/utils/helpers';

import DSPCta from './components/DspCta';
import PostJobAutoCompleteCta from './components/PostJobAutoCompleteCta';
import PostJobCta from './components/PostJobCta';
import {
  ContainerStyled,
  CobrandingStyled,
  HeaderStickyStyled,
  WeddingLogoStyled,
  LogoWrapperStyled
} from './styled/HeaderStickyStyled';

const propTypes = {
  autocompleteTitle: PropTypes.string,
  category: CategoryObjectType,
  ctaKind: PropTypes.oneOf(Object.values(CTA_TYPES)),
  directLeadsNumber: PropTypes.string,
  postcode: PropTypes.string,
  useGetFreeQuoteText: PropTypes.bool,
  jobFormInitiatedPageName: PropTypes.string,
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const SITE_URL = getSiteUrl();
const DOMAIN = getDomain();

const LogoLink = () => {
  switch (DOMAIN) {
    case 'wedding':
      return (
        <Link href="/" aria-label="wedding.com.au logo">
          <WeddingLogoStyled />
        </Link>
      );
    default: {
      return (
        <Link href={SITE_URL}>
          <CobrandingStyled />
        </Link>
      );
    }
  }
};

const HeaderSticky = ({
  autocompleteTitle,
  directLeadsNumber,
  ctaKind = CTA_TYPES.POST_JOB,
  category,
  useGetFreeQuoteText = false,
  postcode,
  jobFormInitiatedPageName
}) => {
  const { controller } = useContext(JobFormControllerContext);
  const [location, setlocation] = useState({
    value: '',
    valid: false,
    id: null
  });
  const [locationOptions, setLocationOptions] = useState([]);
  const [showStickyHeader, setShowStickyHeader] = useState(false);
  const checkScrollDistance = useCallback(
    (scrollDistance) => {
      if (scrollDistance > 73 && !showStickyHeader) {
        setShowStickyHeader(true);
      }
      if (scrollDistance < 73 && showStickyHeader) {
        setShowStickyHeader(false);
      }
    },
    [showStickyHeader]
  );

  const handleScroll = useCallback(() => {
    let timeout = undefined;
    const scrollDistance = Math.max(
      window.scrollY,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );

    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      checkScrollDistance(scrollDistance);
      clearTimeout(timeout);
    }, 10);
  }, [checkScrollDistance]);

  useEffect(() => {
    window.document.addEventListener('scroll', handleScroll);
    return () => window.document.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  const handleCallClick = useCallback(() => {
    document.location.href = `tel:${directLeadsNumber}`;
    const categoryId = category?.id;
    const categorySlug = category?.slug;
    Tracker.dataLayer.push({
      action: 'call_text',
      category: 'phone_number',
      category_id: categoryId ? String(categoryId) : null,
      category_name: categorySlug || null,
      event: 'interaction',
      call_id: directLeadsNumber,
      label: 'phone_dsp'
    });
  }, [directLeadsNumber, category]);

  const [getLocations] = useLazyQuery(AUTOCOMPLETE_LOCATION, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const { autocompleteLocation } = data;
      if (autocompleteLocation) {
        const formatted = autocompleteLocation.map((value) => ({
          ...value,
          name: value.value,
          value: value.value
        }));
        setLocationOptions(formatted);
      }
    },
    onError: (error) => {
      DataDogRumAgent.addRumError(
        error,
        'HeaderSticky.jsx | AUTOCOMPLETE_LOCATION query'
      );
    }
  });

  const openJobForm = useCallback(async () => {
    const categoryId = category?.id;
    const locationId = location?.id;
    const locationName = location?.value;
    const categoryName = category?.name;
    const postcode =
      locationName && locationName.includes(',')
        ? extractPostcode(locationName)
        : undefined;
    await controller?.open({
      categoryId,
      locationId,
      locationName,
      postcode,
      categoryName,
      page: jobFormInitiatedPageName,
      section: AnalyticsJobFormSection.NavigationBar,
      ctaText: useGetFreeQuoteText
        ? AnalyticsJobFormCtaText.GetFreeQuotes
        : AnalyticsJobFormCtaText.PostAJob
    });
  }, [
    category,
    location,
    controller,
    jobFormInitiatedPageName,
    useGetFreeQuoteText
  ]);

  useEffect(() => {
    const { id: locationId, valid: validLocation } = location;
    if (locationId || validLocation) {
      openJobForm();
    }
  }, [location, openJobForm]);

  const handleChange = useCallback(({ target: { value } }) => {
    if (value && value.trim().length > 0) {
      getLocations({ variables: { term: value } });
    }
    setlocation({ valid: false, id: null, value });
  }, [getLocations]);

  const handleSelect = useCallback((option) => {
    const location = locationOptions.find(
      (item) => item.value === option.value
    );
    setlocation({
      value: option.value,
      valid: Boolean(location),
      id: location.id
    });
  }, [locationOptions]);

  const handleJobFormLaunch = useCallback(async () => {
    const valid = location?.valid;
    const value = location?.value;
    const locationId = location?.id;
    const categoryId = category?.id;
    const categoryName = category?.name;
    let jobFormPostcode = postcode;
    let locationName = value;
    // Extract postcode from locationName if it contains a comma
    if (value && valid && value.includes(',')) {
      jobFormPostcode = extractPostcode(value);
    }

    // If location is not valid, use supplidd postcode from props as locationName
    if (!valid && !value) {
      locationName = postcode;
    }

    await controller?.open({
      categoryId,
      locationId,
      locationName,
      page: jobFormInitiatedPageName,
      section: AnalyticsJobFormSection.NavigationBar,
      ctaText: useGetFreeQuoteText
        ? AnalyticsJobFormCtaText.GetFreeQuotes
        : AnalyticsJobFormCtaText.PostAJob,
      categoryName,
      postcode: jobFormPostcode
    });
  }, [
    category,
    controller,
    jobFormInitiatedPageName,
    location,
    postcode,
    useGetFreeQuoteText
  ]);

  const ctaOptions = useMemo(
    () => ({
      [CTA_TYPES.POST_JOB]: {
        CTAComponent: PostJobCta,
        ctaProps: {
          onClick: handleJobFormLaunch,
          useGetFreeQuoteText
        }
      },
      [CTA_TYPES.POST_JOB_AUTOCOMPLETE]: {
        CTAComponent: PostJobAutoCompleteCta,
        ctaProps: {
          onClick: handleJobFormLaunch,
          onChange: handleChange,
          onSelect: handleSelect,
          items: locationOptions.map(({ name, value }) => ({ name, value })),
          autocompleteTitle,
          value: location.value,
          useGetFreeQuoteText
        }
      },
      [CTA_TYPES.DSP]: {
        CTAComponent: DSPCta,
        ctaProps: {
          handleCallClick,
          directLeadsNumber
        }
      }
    }),
    [
      autocompleteTitle,
      directLeadsNumber,
      handleSelect,
      handleChange,
      handleCallClick,
      handleJobFormLaunch,
      location,
      locationOptions,
      useGetFreeQuoteText
    ]
  );

  const { CTAComponent, ctaProps } = ctaOptions[ctaKind];

  return (
    <HeaderStickyStyled
      $showStickyHeader={showStickyHeader}
      data-testid="header-sticky"
    >
      <ContainerStyled>
        <LogoWrapperStyled>
          <LogoLink />
        </LogoWrapperStyled>
        <CTAComponent {...ctaProps} />
      </ContainerStyled>
    </HeaderStickyStyled>
  );
};

HeaderSticky.propTypes = propTypes;

export default HeaderSticky;
