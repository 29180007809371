import { Modal } from '@oneflare/flarekit/lib/components';
import { media } from '@oneflare/flarekit/lib/libs';
import { styled } from 'styled-components';

export const ContactModalStyled = styled(Modal)`
  &__container {
    border-radius: 12px;
  }
`;

export const Contact = styled.div(({ theme }) => `
  color: ${theme.color.gunMetal};
  line-height: 1.5;
`);

export const ContactHeader = styled.h2(({ theme: { spacing } }) => `
  margin: ${spacing.xl} 0 ${spacing.sm};
`);

export const ContactOptions = styled.div`
  display: flex;

  ${({ theme }) => `
    gap: ${theme.spacing.lg};
    margin-top: ${theme.spacing.xl};
  `}

  ${media.smDown`
    flex-direction: column;
  `}
`;

export const ContactOption = styled.div(({ theme }) => `
  background-color: ${theme.color.tin};
  padding: ${theme.spacing.lg};
  border-radius: ${theme.spacing.sm};
  flex: 1;
  display: flex;
  flex-direction: column;

  svg {
    fill: ${theme.color.primary};
    width: ${theme.spacing.xl};
  }
`);

export const ContactType = styled.h3(({ theme }) => `
  margin-bottom: ${theme.spacing.sm};
  color: ${theme.color.charcoal};
`);

export const ContactText = styled.div(({ theme }) => `
  color: ${theme.color.gunMetal};
`);
