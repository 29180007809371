import PropTypes from 'prop-types';

import { ButtonStyled } from '../styled/HeaderStickyStyled';

const DspCta = ({
  handleCallClick = () => {},
  directLeadsNumber = null
}) => {
  return (
    <ButtonStyled
      label={`Call ${directLeadsNumber}`}
      kind="primary"
      data-testid="header-sticky-dsp-cta"
      onClick={handleCallClick}
    />
  );
};

DspCta.propTypes = {
  directLeadsNumber: PropTypes.string,
  handleCallClick: PropTypes.func
};

export default DspCta;
