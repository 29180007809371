import { Telephone, Mail, Anchor } from '@oneflare/flarekit/lib/components';
import React from 'react';

import {
  ContactModalStyled,
  ContactHeader,
  ContactOptions,
  ContactOption,
  ContactType,
  ContactText,
  Contact
} from '../styled/ContactModal';

const ContactModal = (props: {
  onClose?(): void
  show: boolean
}) => (
  <ContactModalStyled
    isCloseable
    {...props}
  >
    <Contact>
      <ContactHeader>Get in touch with us</ContactHeader>

      Speak with a Sales Consultant to learn more. We're available Monday to Friday, 9am - 5:30pm (AEST).

      <ContactOptions>
        <ContactOption>
          <Telephone />
          <ContactType>On the phone</ContactType>
          <ContactText>
            Call us on <Anchor href="tel:1300138827">1300 138 827</Anchor>
          </ContactText>
        </ContactOption>

        <ContactOption>
          <Mail />
          <ContactType>Send us an email</ContactType>
          <ContactText>
            <Anchor href="mailto:sales@oneflare.com.au">sales@oneflare.com.au</Anchor>
          </ContactText>
        </ContactOption>
      </ContactOptions>
    </Contact>
  </ContactModalStyled>
);

export default ContactModal;
