import Loader from '@oneflare/flarekit/lib/components/Loader';
import { styled } from 'styled-components';

export const LoadingWrapperStyled = styled.div`
  margin: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoaderStyled = styled(Loader).attrs(({ theme }) => ({
  kind: 'threeDots',
  fill: theme.color.aluminium
}))`
  width: 40px;
  height: 40px;
`;
