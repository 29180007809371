
// lib/utils/featureFlag.ts
import { DataDogRumAgent } from 'lib/datadog/initializeDatadog';
import { GET_FEATURE_FLAGS } from 'queries/shared/getFeatureFlag';

import ApolloRun from './apolloRun';

export class FeatureFlag {
  private features = new Map<string, boolean>();
  private client = new ApolloRun();

  async loadFeatureFlags(names: string[]): Promise<void> {
    const [data, errors] = await this.client.query(GET_FEATURE_FLAGS, {
      names
    });

    if (errors) {
      DataDogRumAgent.addRumError(errors, 'Oneflare | Shared | Get Feature Flags Query');
      return;
    }

    const featureFlags = data?.appConfiguration?.featureFlags || [];

    // Append the new feature flags to the existing map
    featureFlags.forEach((feature: { name: string; enabled: boolean }) => {
      this.features.set(feature.name, feature.enabled);
    });
  }

  async isEnabled(featureName: string): Promise<boolean> {
    if (!this.features.has(featureName)) {
      await this.loadFeatureFlags([featureName]);
    }
    return this.features.get(featureName) ?? false;
  }
}

const featureFlag = new FeatureFlag();

export default featureFlag;
