import Button from '@oneflare/flarekit/lib/components/Button';
import Paragraph from '@oneflare/flarekit/lib/components/Paragraph';
import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const BillingOptionsStyled = styled.div`
  padding: ${({ theme }) => theme.spacing.lg};

  ${media.lgUp`
    flex: 1;
    padding: 0px 32px 48px 48px;
  `}
`;

export const BillingSummaryStyled = styled.div`
  border-left: none;
  border-top: 1px solid ${({ theme }) => theme.color.nickel};
  padding: ${({ theme }) => theme.spacing.lg};
  width: 100%;

  ${media.lgUp`
    background: ${({ theme }) => theme.color.tin};
    width: 330px;
    padding: 32px;
  `}
`;

export const ButtonStyled = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing.lg};
  width: 100%;
`;

export const ParagraphStyled = styled(Paragraph)`
  margin-top: ${({ theme }) => theme.spacing.xs};
`;

export const SignUpContainerStyled = styled.div`
  flex-direction: column;

  ${media.lgUp`
    display: flex;
    flex-direction: row;
  `}
`;

export const SummaryItemStyled = styled.div`
  color: ${({ theme }) => theme.color.gunMetal};
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;

export const SummaryTitleStyled = styled.h3`
  border-bottom: 1px solid ${({ theme }) => theme.color.nickel};
  color: ${({ theme }) => theme.color.charcoal};
  font-weight: 700;
  padding-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const SummaryTotalStyled = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.nickel};
  color: ${({ theme }) => theme.color.gunMetal};
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing.lg};
  padding-top: ${({ theme }) => theme.spacing.lg};
`;

export const TitleStyled = styled.h2`
  color: ${({ theme }) => theme.color.charcoal};
  font-family: ${({ theme }) => theme.font.secondaryBold};
  margin-top: 32px;
  margin-bottom: ${({ theme }) => theme.spacing.lg};
`;

export const TermAndConditionStyled = styled.div`
  margin-top: ${({ theme }) => theme.spacing.md};
  font-family: ${({ theme }) => theme.font.primaryRegular};
  color: ${({ theme }) => theme.color.spaceGrey};
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
`;

export const LinkStyled = styled.a`
  color: ${({ theme }) => theme.color.spaceGrey};
`;
