import media from '@oneflare/flarekit/lib/libs/media';
import { styled } from 'styled-components';

export const Container = styled.div(({ theme: { spacing } }) => `
  margin: 0 auto;
  width: 910px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.xlUp`
    width: 1120px;
  `}

  > h2 {
    margin: ${spacing.xxxxxl} auto;
  }

  > .Dropdown {
    margin-left: auto;
  }

  #PlanSelect__dropdown {
    padding-top: ${spacing.xs};
    padding-bottom: ${spacing.xs};
    font-size: 18px;
  }
`);
